export enum PermissionEnum {
  DASHBOARD_LIST = 'DASHBOARD_LIST',

  ORDERS_ARCHIVE = 'ORDERS_ARCHIVE',
  ORDERS_LIST_ALL = 'ORDERS_LIST_ALL',
  ORDERS_BUFFER = 'ORDERS_BUFFER',
  ORDERS_CREATE = 'ORDERS_CREATE',
  ORDERS_UPDATE = 'ORDERS_UPDATE',
  ORDERS_UPDATE_ALL = 'ORDERS_UPDATE_ALL',
  ORDERS_PROTOCOL_EDIT = 'ORDERS_PROTOCOL_EDIT',
  ORDERS_PROTOCOL_DELETE = 'ORDERS_PROTOCOL_DELETE',
  ORDERS_CHANGE_USER = 'ORDERS_CHANGE_USER',
  ORDERS_CHANGE_STATUS = 'ORDERS_CHANGE_STATUS',

  USERS_LIST = 'USERS_LIST',
  USERS_DETAILS = 'USERS_DETAILS',
  USERS_RESET_PASSWORD = 'USERS_RESET_PASSWORD',
  USERS_DELETE = 'USERS_DELETE',

  COMPANIES_LIST = 'COMPANIES_LIST',
  COMPANIES_CREATE = 'COMPANIES_CREATE',
  COMPANIES_EDIT = 'COMPANIES_EDIT',
  COMPANIES_DELETE = 'COMPANIES_DELETE',

  USERS_UPDATE = 'USERS_UPDATE',
  USERS_CREATE = 'USERS_CREATE',

  CLIENTS_LIST = 'CLIENTS_LIST',
  CLIENTS_CREATE = 'CLIENTS_CREATE',
  CLIENTS_DETAILS = 'CLIENTS_DETAILS',
  CLIENTS_DELETE = 'CLIENTS_DELETE',
  CLIENTS_UPDATE = 'CLIENTS_UPDATE',

  CLIENTS_LIST_DEPARTMENTS = 'CLIENTS_LIST_DEPARTMENTS',
  CLIENTS_CREATE_DEPARTMENT = 'CLIENTS_CREATE_DEPARTMENT',
  CLIENTS_UPDATE_DEPARTMENT = 'CLIENTS_UPDATE_DEPARTMENT',
  CLIENTS_DELETE_DEPARTMENT = 'CLIENTS_DELETE_DEPARTMENT',
  CLIENTS_DEPARTMENT_DEVICES = 'CLIENTS_DEPARTMENT_DEVICES',

  DEVICES_GROUPS_LIST = 'DEVICES_GROUPS_LIST',
  DEVICES_GROUPS_DETAILS = 'DEVICES_GROUPS_DETAILS',
  DEVICES_GROUPS_CREATE = 'DEVICES_GROUPS_CREATE',
  DEVICES_GROUPS_UPDATE = 'DEVICES_GROUPS_UPDATE',

  DEVICES_GROUPS_CREATE_FIELD = 'DEVICES_GROUPS_CREATE_FIELD',
  DEVICES_GROUPS_UPDATE_FIELD = 'DEVICES_GROUPS_UPDATE_FIELD',
  DEVICES_GROUPS_DELETE_FIELD = 'DEVICES_GROUPS_DELETE_FIELD',

  DEVICES_LIST = 'DEVICES_LIST',
  DEVICES_DETAILS = 'DEVICES_DETAILS',
  DEVICES_ORDERS = 'DEVICES_ORDERS',
  DEVICES_ORDERS_ALL = 'DEVICES_ORDERS_ALL',
  DEVICES_ORDERS_PERIODIC = 'DEVICES_ORDERS_PERIODIC',
  DEVICES_CREATE = 'DEVICES_CREATE',
  DEVICES_UPDATE = 'DEVICES_UPDATE',
  DEVICES_DELETE = 'DEVICES_DELETE',

  ORDER_TYPE_LIST = 'ORDER_TYPE_LIST',
  ORDER_TYPE_CREATE = 'ORDER_TYPE_CREATE',
  ORDER_TYPE_DELETE = 'ORDER_TYPE_DELETE',
  ORDER_TYPE_UPDATE = 'ORDER_TYPE_UPDATE',

  PERIODIC_ORDERS_LIST = 'PERIODIC_ORDERS_LIST',
  PERIODIC_ORDERS_CREATE = 'PERIODIC_ORDERS_CREATE',
  PERIODIC_ORDERS_UPDATE = 'PERIODIC_ORDERS_UPDATE',
  PERIODIC_ORDERS_DETAILS = 'PERIODIC_ORDERS_DETAILS',
  PERIODIC_ORDERS_REMOVE = 'PERIODIC_ORDERS_REMOVE',
}
