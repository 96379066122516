import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CoreAuthModule } from './auth/auth.module';
import { UserService } from './user';
import { CoreApi } from './core.api';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { translateHttpLoaderFactory } from './factories';

@NgModule({
  imports: [
    HttpClientModule,
    CoreAuthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'pl',
    }),
  ],
  providers: [CoreApi, UserService],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule.');
    }
  }
}
