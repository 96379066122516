import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from '../../shared.module';

@Component({
  standalone: true,
  selector: 'dt-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['../../../../styles/shared/dialog.scss'],
  imports: [SharedModule],
})
export class DialogComponent {
  @Input() public label: string = '';
  @Input() public acceptButtonText: string = '';
  @Input() public cancelButtonText: string = '';
  @Input() public isAcceptButtonHidden: boolean = false;
  @Input() public isCancelButtonHidden: boolean = false;
  @Output() public accepted: EventEmitter<void> = new EventEmitter<void>();
  @Output() public cancelled: EventEmitter<void> = new EventEmitter<void>();
}
