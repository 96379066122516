import { Component, Input } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { NavigationBasicItemComponent } from './components/basic-item/basic-item.component';
import { INavigationItem } from '../../interfaces';
import { NavigationMenuItemComponent } from './components/menu-item/menu-item.component';

@Component({
  standalone: true,
  selector: 'dt-navigation-component',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  imports: [SharedModule, NavigationMenuItemComponent, NavigationBasicItemComponent],
})
export class NavigationComponent {
  @Input() public items: INavigationItem[] = [];
}
