import { Component, EventEmitter, Input, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { SharedModule } from '../../shared.module';

@Component({
  standalone: true,
  selector: 'dt-search-input-component',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  imports: [SharedModule],
})
export class SearchInputComponent {
  @Input() public id: string = '';
  @Input() public name: string = 'search';
  @Input() public value: string = '';
  @Input() public placeholder: string = 'COMMON.SEARCH_PLACEHOLDER';
  @Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();

  private _subject: Subject<string> = new Subject<string>();

  constructor() {
    // Emit value with debounce
    this._subject.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => this.valueChange.emit(value));
  }

  public clearValue(): void {
    // Set default value
    this.value = '';

    // Emit change without debounce
    this.valueChange.emit('');
  }

  public onKeyUp(): void {
    this._subject.next(this.value);
  }
}
