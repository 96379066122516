import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationComponent } from '../../shared/components';
import { INavigationItem } from '../../shared/interfaces';
import { PermissionEnum, UserService } from '../../core/user';
import { Subject } from 'rxjs';

@Component({
  standalone: true,
  selector: 'dt-basic-layout',
  templateUrl: './basic.layout.html',
  styleUrls: ['./basic.layout.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [RouterModule, NavigationComponent],
})
export class BasicLayoutContainer implements OnInit, OnDestroy {
  public navigationItems: INavigationItem[] = [];

  private _onDestroy: Subject<void> = new Subject<void>();

  constructor(private _userService: UserService) {}

  public ngOnInit(): void {
    this._userNavigation();
  }

  public ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public logout(): void {
    this._userService.logout();
  }

  private _userNavigation(): void {
    this.navigationItems = [
      {
        type: 'basic',
        label: 'DASHBOARD.MODULE_NAME',
        link: '/dashboard',
        isHidden: !this._userService.hasAccess([PermissionEnum.DASHBOARD_LIST]),
      },
      {
        type: 'menu',
        label: 'ORDERS.MODULE_NAME',
        children: [
          {
            type: 'basic',
            label: 'ORDERS.ORDER_LIST',
            link: '/orders/list',
          },
          {
            type: 'basic',
            label: 'ORDERS.ADD_ORDER',
            link: '/orders/create',
            isHidden: !this._userService.hasAccess([PermissionEnum.ORDERS_CREATE]),
          },
          {
            type: 'spacer',
            isHidden: !this._userService.hasAccess([PermissionEnum.PERIODIC_ORDERS_LIST]),
          },
          {
            type: 'basic',
            label: 'PERIODIC_ORDERS.MODULE_NAME',
            link: '/periodic-orders/list',
            isHidden: !this._userService.hasAccess([PermissionEnum.PERIODIC_ORDERS_LIST]),
          },
          {
            type: 'basic',
            label: 'PERIODIC_ORDERS.ADD_ORDER',
            link: '/periodic-orders/create',
            isHidden: !this._userService.hasAccess([
              PermissionEnum.PERIODIC_ORDERS_LIST,
              PermissionEnum.PERIODIC_ORDERS_CREATE,
            ]),
          },
          {
            type: 'spacer',
            isHidden: !this._userService.hasAccess([PermissionEnum.ORDER_TYPE_LIST]),
          },
          {
            type: 'basic',
            label: 'ORDER_TYPES.MODULE_NAME',
            link: '/order-types/list',
            isHidden: !this._userService.hasAccess([PermissionEnum.ORDER_TYPE_LIST]),
          },
          {
            type: 'spacer',
            isHidden: !this._userService.hasAccess([PermissionEnum.ORDERS_ARCHIVE]),
          },
          {
            type: 'basic',
            label: 'COMMON.ARCHIVE',
            link: '/orders/archive',
            isHidden: !this._userService.hasAccess([PermissionEnum.ORDERS_ARCHIVE]),
          },
        ],
      },
      {
        type: 'menu',
        label: 'DEVICES.MODULE_NAME',
        isHidden: !this._userService.hasAccess(
          [PermissionEnum.DEVICES_LIST, PermissionEnum.DEVICES_GROUPS_LIST],
          'ONE_OF',
        ),
        children: [
          {
            type: 'basic',
            label: 'DEVICES.DEVICES_LIST',
            link: '/devices/list',
            isHidden: !this._userService.hasAccess([PermissionEnum.DEVICES_LIST]),
          },
          {
            type: 'basic',
            label: 'DEVICES.ADD_DEVICE',
            link: '/devices/create',
            isHidden: !this._userService.hasAccess([PermissionEnum.DEVICES_LIST, PermissionEnum.DEVICES_CREATE]),
          },
          {
            type: 'spacer',
            isHidden: !this._userService.hasAccess([PermissionEnum.DEVICES_LIST, PermissionEnum.DEVICES_GROUPS_LIST]),
          },
          {
            type: 'basic',
            label: 'DEVICES_GROUPS.DEVICES_GROUPS_LIST',
            link: '/devices-groups',
            isHidden: !this._userService.hasAccess([PermissionEnum.DEVICES_GROUPS_LIST]),
          },
          {
            type: 'basic',
            label: 'DEVICES_GROUPS.ADD_DEVICE_GROUP',
            link: '/devices-groups/create',
            isHidden: !this._userService.hasAccess([
              PermissionEnum.DEVICES_GROUPS_LIST,
              PermissionEnum.DEVICES_GROUPS_CREATE,
            ]),
          },
        ],
      },
      {
        type: 'basic',
        label: 'CLIENTS.MODULE_NAME',
        link: '/clients',
        isHidden: !this._userService.hasAccess([PermissionEnum.CLIENTS_LIST]),
      },
      {
        type: 'basic',
        label: 'COMPANIES.MODULE_NAME',
        link: '/companies',
        isHidden: !this._userService.hasAccess([PermissionEnum.COMPANIES_LIST]),
      },
      {
        type: 'basic',
        label: 'USERS.MODULE_NAME',
        link: '/users',
        isHidden: !this._userService.hasAccess([PermissionEnum.USERS_LIST]),
      },
      {
        type: 'menu',
        label: 'COMMON.ACCOUNT',
        children: [
          {
            type: 'basic',
            label: 'COMMON.CHANGE_PASSWORD',
            link: '/auth/change-password',
          },
          {
            type: 'basic',
            label: 'COMMON.LOGOUT',
            link: '/',
            fn: (): void => this.logout(),
          },
        ],
      },
    ];
  }
}
