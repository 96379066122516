import { Component, Input } from '@angular/core';
import { SharedModule } from '../../shared.module';

@Component({
  standalone: true,
  selector: 'dt-info-component',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  imports: [SharedModule],
})
export class InfoComponent {
  @Input() public type: 'info' | 'warning' | 'error' | 'text' = 'info';
  @Input() public title?: string;
  @Input() public message?: string;
}
