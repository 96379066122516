import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import * as moment from 'moment/moment';
import { SharedModule } from '../../shared.module';
import { IMonthYearFilterOutput } from './month-year-filter-output.i';
import { ISelectItem } from '../../interfaces';
import { generateYears, monthsData } from '../../utils';

@Component({
  standalone: true,
  selector: 'dt-month-year-filter-component',
  templateUrl: './month-year-filters.component.html',
  imports: [SharedModule],
})
export class MonthYearFiltersComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public month: number = moment().month() + 1;
  @Input() public year: number = moment().year();

  @Output() public changed: EventEmitter<IMonthYearFilterOutput> = new EventEmitter<IMonthYearFilterOutput>();

  public form: FormGroup;
  public months: ISelectItem[] = [{ label: 'WHOLE_YEAR', value: 0 }, ...monthsData];
  public years: ISelectItem[] = generateYears(2022);

  private _onDestroy: Subject<void> = new Subject<void>();

  constructor(private _formBuilder: FormBuilder) {
    this.form = this._formBuilder.group({
      month: [this.month],
      year: [this.year],
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['month']?.currentValue !== undefined) {
      this.form.controls['month'].setValue(this.month);
    }
    if (changes['year']?.currentValue !== undefined) {
      this.form.controls['year'].setValue(this.year);
    }
  }

  public ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((changes) => {
      this.changed.emit({ month: changes.month, year: changes.year });
    });
  }

  public ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
