<ng-container *ngIf="item">

  <!-- Function link -->
  <ng-container *ngIf="item.fn">
    <button class="navigation-item" (click)="item.fn()">
      <span>{{item.label || '' | translate}}</span>
    </button>
  </ng-container>

  <!-- In app link -->
  <ng-container *ngIf="!item.fn && item.link && !item.isExternalLink">
    <button class="navigation-item" [routerLink]="item.link">{{item.label || '' | translate}}</button>
  </ng-container>

  <!-- External link -->
  <ng-container *ngIf="!item.fn && item.link && item.isExternalLink">
    <a class="navigation-item" [href]="item.link" [download]="item.isDownloadLink"
       [target]="item.target">{{item.label || '' | translate}}</a>
  </ng-container>
</ng-container>
