import { Component, Input } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { INavigationItem } from '../../../../interfaces';

@Component({
  standalone: true,
  selector: 'dt-navigation-basic-item-component',
  templateUrl: './basic-item.component.html',
  styleUrls: ['../../navigation-items.scss'],
  imports: [SharedModule],
})
export class NavigationBasicItemComponent {
  @Input() public item?: INavigationItem;
}
