import { Component, Input } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { INavigationItem } from '../../../../interfaces';
import { NavigationBasicItemComponent } from '../basic-item/basic-item.component';

@Component({
  standalone: true,
  selector: 'dt-navigation-menu-component',
  templateUrl: './menu-item.component.html',
  styleUrls: ['../../navigation-items.scss', './menu-item.component.scss'],
  imports: [SharedModule, NavigationBasicItemComponent],
})
export class NavigationMenuItemComponent {
  @Input() public item?: INavigationItem;
}
