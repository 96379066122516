<ng-container *ngIf="item">
  <button class="navigation-item" [matMenuTriggerFor]="menu">
    <span>{{item.label || '' | translate}}</span>
    <mat-icon class="icon-xs">expand_more</mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="navigation-menu">
    <ng-container *ngFor="let childItem of item.children">

      <ng-container *ngIf="!childItem.isHidden">
        <ng-container *ngIf="childItem.type === 'basic'">
          <dt-navigation-basic-item-component [item]="childItem"></dt-navigation-basic-item-component>
        </ng-container>

        <ng-container *ngIf="childItem.type === 'spacer'">
          <div class="navigation-spacer"></div>
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-menu>
</ng-container>
