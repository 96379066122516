<div class="navigation">
  <div class="logo">
    <img [routerLink]="['/', 'orders']" src="assets/images/logo.svg" alt="dotcrm" class="cursor-pointer" />
  </div>
  <div class="navigation-items">
    <ng-container *ngFor="let item of items">

      <ng-container *ngIf="!item.isHidden">

        <ng-container *ngIf="item.type === 'basic'">
          <dt-navigation-basic-item-component [item]="item"></dt-navigation-basic-item-component>
        </ng-container>

        <ng-container *ngIf="item.type === 'menu'">
          <dt-navigation-menu-component [item]="item"></dt-navigation-menu-component>
        </ng-container>

        <ng-container *ngIf="item.type === 'spacer'">
          <div class="navigation-spacer"></div>
        </ng-container>

      </ng-container>

    </ng-container>
  </div>
</div>
