import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class CoreAuthInterceptor implements HttpInterceptor {
  constructor(private _router: Router, private _authService: AuthService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Set authorization headers
    const clonedRequest = req.clone({
      setHeaders: {
        'X-XSRF-TOKEN': this._authService.getCookie('XSRF-TOKEN'),
      },
      withCredentials: true,
    });

    return next.handle(clonedRequest).pipe(
      catchError((error) => {
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // TODO: Clean user data
          this._router.navigate(['unauthenticated']);
        }
        // Catch "419" responses. CSRF Token mismatch.
        if (error instanceof HttpErrorResponse && error.status === 419) {
          // TODO: Clean user data
          window.location.reload();
        }

        return throwError(error);
      }),
    );
  }
}
