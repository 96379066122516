import { Component } from '@angular/core';

@Component({
  selector: 'dt-root',
  template: `
    <ng-http-loader [debounceDelay]="100"></ng-http-loader>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {}
