import { Injectable } from '@angular/core';
import { ModuleType } from './module.type';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  public reportError(module: ModuleType, comment: string, error: any): void {
    console.error('errorHandler', module, comment, error);
  }
}
