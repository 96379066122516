import { Component, Input } from '@angular/core';
import { SharedModule } from '../../shared.module';

@Component({
  standalone: true,
  selector: 'dt-panel-component',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  imports: [SharedModule],
})
export class PanelComponent {
  @Input() public label: string = '';
  @Input() public noPadding: boolean = false;
}
