import { NgModule } from '@angular/core';
import { EmptyLayoutContainer } from './empty/empty.layout';
import { BasicLayoutContainer } from './basic/basic.layout';

const LAYOUTS: any[] = [EmptyLayoutContainer, BasicLayoutContainer];

@NgModule({
  imports: [...LAYOUTS],
  exports: [...LAYOUTS],
})
export class LayoutsModule {}
