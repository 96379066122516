import { Component, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  selector: 'dt-empty-layout',
  templateUrl: './empty.layout.html',
  styleUrls: ['./empty.layout.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [RouterModule],
})
export class EmptyLayoutContainer {}
