const convertValue = (value: any): any => {
  if (typeof value === 'boolean') {
    return Number(value);
  } else {
    return value;
  }
};
export const buildQueryParams = (params: any): string => {
  let query = '';
  const keys = Object.keys(params);
  for (const key of keys) {
    if (Array.isArray(params[key])) {
      for (const param of params[key]) {
        const value = convertValue(param);
        if (value !== null) {
          query += `&${key}[]=${value}`;
        }
      }
    } else {
      const value = convertValue(params[key]);

      if (params[key] !== null && value !== '') {
        query += `&${key}=${value}`;
      }
    }
  }
  if (query.length > 0) {
    query = '?' + query.substr(1, query.length);
  }
  return query;
};
