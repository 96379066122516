import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '../inject-tokens';
import { Observable } from 'rxjs';
import { IApiResponse, IAppConfig, ILoggedUser } from './interfaces';

@Injectable()
export class CoreApi {
  constructor(@Inject(APP_CONFIG) private readonly _config: IAppConfig, private _httpClient: HttpClient) {}

  public me(): Observable<IApiResponse<ILoggedUser>> {
    return this._httpClient.get<IApiResponse<ILoggedUser>>(`${this._config.apiUrl}/auth/me`);
  }

  public csrf(): Observable<void> {
    return this._httpClient.get<void>(`${this._config.csrfUrl}/sanctum/csrf-cookie`);
  }

  public logout(): Observable<IApiResponse<void>> {
    return this._httpClient.post<IApiResponse<void>>(`${this._config.apiUrl}/auth/logout`, {});
  }
}
