import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { CoreApi } from '../core.api';
import { ILoggedUser } from '../interfaces';
import { PermissionEnum } from './permission.enum';
import { ErrorHandlerService, NotificationService } from '../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class UserService {
  private _user: BehaviorSubject<ILoggedUser | null> = new BehaviorSubject<ILoggedUser | null>(null);

  constructor(
    private _coreApi: CoreApi,
    private _translateService: TranslateService,
    private _notificationService: NotificationService,
    private _errorHandlerService: ErrorHandlerService,
    private _router: Router,
  ) {}

  public set user(user: ILoggedUser) {
    this._user.next(user);
  }

  public get user$(): Observable<ILoggedUser | null> {
    return this._user.asObservable();
  }

  public getUserSnapshot(): ILoggedUser | null {
    return this._user.getValue();
  }

  public hasAccess(permissions: PermissionEnum[], mode: 'EXACT' | 'ONE_OF' = 'EXACT'): boolean {
    const user: ILoggedUser | null = this._user.getValue();

    if (user) {
      if (mode === 'ONE_OF') {
        return permissions.some((permission) => user.permissions.indexOf(permission) > -1);
      } else {
        return permissions.every((permission) => user.permissions.indexOf(permission) > -1);
      }
    }
    return false;
  }

  public fetchLoggedUser(): Observable<ILoggedUser> {
    return this._coreApi.me().pipe(
      map((response) => response.data),
      tap((user) => (this.user = user)),
    );
  }

  public logout(): void {
    this._coreApi.logout().subscribe({
      next: () => {
        this._notificationService.success(this._translateService.instant('AUTH.SUCCESS_LOGOUT'));
        this._router.navigate(['/', 'auth', 'login']);
      },
      error: (err) => {
        this._errorHandlerService.reportError('AUTH', 'User service', err);
        this._notificationService.error(this._translateService.instant('EXCEPTIONS.UNKNOWN_ERROR'), 8000);
      },
    });
  }
}
