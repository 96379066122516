<div class="search-input">
  <mat-icon class="icon-search size-sm">search</mat-icon>
  <input type="text"
         autocomplete="off"
         [id]="id"
         [name]="name"
         [placeholder]="placeholder | translate"
         [(ngModel)]="value"
         (keyup)="onKeyUp()" />
  <mat-icon *ngIf="value" (click)="clearValue()" matRipple class="cursor-pointer size-sm icon-clear">close</mat-icon>
</div>
