import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { APP_CONFIG } from './inject-tokens';
import { environment } from '../environments/environment';
import { CoreApi } from './core/core.api';
import { initializeAppFactory } from './core/factories';
import { LayoutsModule } from './layouts/layouts.module';
import { AccessDeniedPage } from './access-denied.page';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { UserService } from './core/user';

@NgModule({
  declarations: [AppComponent, AccessDeniedPage],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgHttpLoaderModule.forRoot(),
    CoreModule,
    AppRoutingModule,
    LayoutsModule,
  ],
  providers: [
    {
      provide: APP_CONFIG,
      useValue: environment,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [CoreApi, UserService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
