import { ISelectItem } from '../interfaces';

export const monthsData: ISelectItem[] = [
  { label: 'JANUARY', value: 1 },
  { label: 'FEBRUARY', value: 2 },
  { label: 'MARCH', value: 3 },
  { label: 'APRIL', value: 4 },
  { label: 'MAY', value: 5 },
  { label: 'JUNE', value: 6 },
  { label: 'JULY', value: 7 },
  { label: 'AUGUST', value: 8 },
  { label: 'SEPTEMBER', value: 9 },
  { label: 'OCTOBER', value: 10 },
  { label: 'NOVEMBER', value: 11 },
  { label: 'DECEMBER', value: 12 },
];
