import { Component, Input } from '@angular/core';
import { SharedModule } from '../../shared.module';

@Component({
  standalone: true,
  selector: 'dt-panel-item-component',
  templateUrl: './panel-item.component.html',
  styleUrls: ['./panel-item.component.scss'],
  imports: [SharedModule],
})
export class PanelItemComponent {
  @Input() public label: string | undefined;
  @Input() public value: string | number | undefined | null;
  @Input() public labelWidth: string = '160px';
  @Input() public noBorder: boolean = false;
}
