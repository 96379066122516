import { Observable } from 'rxjs';
import { CoreApi } from '../core.api';
import { UserService } from '../user';

export const initializeAppFactory = (coreApi: CoreApi, userService: UserService): (() => Observable<void>) => {
  return () =>
    new Observable<void>((subscriber) => {
      coreApi.csrf().subscribe(() =>
        userService.fetchLoggedUser().subscribe({
          next: () => {
            subscriber.next();
            subscriber.complete();
          },
          error: () => {
            subscriber.next();
            subscriber.complete();
          },
        }),
      );
    });
};
