import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { INotificationService } from './notification-service.i';

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements INotificationService {
  constructor(private _matSnackBar: MatSnackBar) {}

  public info(message: string, duration: number = 3000): void {
    this._matSnackBar.open(message, 'OK', {
      panelClass: 'notification-info',
      duration,
    });
  }

  public success(message: string, duration: number = 3000): void {
    this._matSnackBar.open(message, 'OK', {
      panelClass: 'notification-success',
      duration,
    });
  }

  public error(message: string, duration: number = 3000): void {
    this._matSnackBar.open(message, 'OK', {
      panelClass: 'notification-error',
      duration,
    });
  }
}
