<form [formGroup]="form">
  <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="end center">
    <mat-form-field class="mat-mdc-no-subscript">
      <mat-label>{{'COMMON.MONTH' | translate}}</mat-label>
      <mat-select formControlName="month">
        <mat-option *ngFor="let month of months" [value]="month.value">
          {{'COMMON.' + month.label | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="mat-mdc-no-subscript">
      <mat-label>{{'COMMON.YEAR' | translate}}</mat-label>
      <mat-select formControlName="year">
        <mat-option *ngFor="let year of years" [value]="year.value">{{year.label}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
