import { Component, Input } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { IBreadcrumb } from '../../interfaces/breadcrumb.i';

@Component({
  standalone: true,
  selector: 'dt-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  imports: [SharedModule],
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() public items: IBreadcrumb[] = [];
}
